import { default as aboutJrWDStVOuoMeta } from "/opt/buildhome/repo/src/pages/about.vue?macro=true";
import { default as indexHRck7nofVTMeta } from "/opt/buildhome/repo/src/pages/app/[organisationId]/index.vue?macro=true";
import { default as indexMjwB30oBzVMeta } from "/opt/buildhome/repo/src/pages/app/index.vue?macro=true";
import { default as indexnwfhmS8BJtMeta } from "/opt/buildhome/repo/src/pages/app/onboarding/index.vue?macro=true";
import { default as onboardingcquesV5cqyMeta } from "/opt/buildhome/repo/src/pages/app/onboarding.vue?macro=true";
import { default as indexhykZpJB4d7Meta } from "/opt/buildhome/repo/src/pages/app/organisations/index.vue?macro=true";
import { default as organisationsFxlbsxAryPMeta } from "/opt/buildhome/repo/src/pages/app/organisations.vue?macro=true";
import { default as indexuCbDhv9SpSMeta } from "/opt/buildhome/repo/src/pages/auth/forgot/index.vue?macro=true";
import { default as verifygN1RQfaAybMeta } from "/opt/buildhome/repo/src/pages/auth/forgot/verify.vue?macro=true";
import { default as resethLUtvJryhKMeta } from "/opt/buildhome/repo/src/pages/auth/reset.vue?macro=true";
import { default as sign_45inJp618j7yErMeta } from "/opt/buildhome/repo/src/pages/auth/sign-in.vue?macro=true";
import { default as sign_45upk5k2qGXM7TMeta } from "/opt/buildhome/repo/src/pages/auth/sign-up.vue?macro=true";
import { default as verify_45emailIuukGPhaI8Meta } from "/opt/buildhome/repo/src/pages/auth/verify-email.vue?macro=true";
import { default as _91_46_46_46slug_932UjpuhiKWFMeta } from "/opt/buildhome/repo/src/pages/blog/[...slug].vue?macro=true";
import { default as contactVKMVVPMR0TMeta } from "/opt/buildhome/repo/src/pages/contact.vue?macro=true";
import { default as downloadshoC2HVhV2IMeta } from "/opt/buildhome/repo/src/pages/downloads.vue?macro=true";
import { default as email_45verifiedgMaPQSOqsSMeta } from "/opt/buildhome/repo/src/pages/email-verified.vue?macro=true";
import { default as indexhGpynX1cdDMeta } from "/opt/buildhome/repo/src/pages/index.vue?macro=true";
import { default as liveFvquQFepQyMeta } from "/opt/buildhome/repo/src/pages/live.vue?macro=true";
import { default as pricingoBHP7hV1mwMeta } from "/opt/buildhome/repo/src/pages/pricing.vue?macro=true";
import { default as privacywMgArANxwsMeta } from "/opt/buildhome/repo/src/pages/privacy.vue?macro=true";
import { default as indexBkLKTvv04gMeta } from "/opt/buildhome/repo/src/pages/products/accessories/index.vue?macro=true";
import { default as indexfLYp6OzQC5Meta } from "/opt/buildhome/repo/src/pages/products/index.vue?macro=true";
import { default as indexjHsuhyQ3ekMeta } from "/opt/buildhome/repo/src/pages/products/scoreboards/index.vue?macro=true";
import { default as maxILkdJ5wOt9Meta } from "/opt/buildhome/repo/src/pages/products/scoreboards/max.vue?macro=true";
import { default as miniBcjhOyyUfpMeta } from "/opt/buildhome/repo/src/pages/products/scoreboards/mini.vue?macro=true";
import { default as sandboxbxDj9y3wdlMeta } from "/opt/buildhome/repo/src/pages/sandbox.vue?macro=true";
import { default as indexDFde3GgXqFMeta } from "/opt/buildhome/repo/src/pages/scores/index.vue?macro=true";
import { default as canoe_45poloRA5Uvn3MVoMeta } from "/opt/buildhome/repo/src/pages/sports/canoe-polo.vue?macro=true";
import { default as indexF4AwVzPwOzMeta } from "/opt/buildhome/repo/src/pages/sports/index.vue?macro=true";
import { default as termsbOpDTp1t6dMeta } from "/opt/buildhome/repo/src/pages/terms.vue?macro=true";
import { default as indexYo8YRB0NQzMeta } from "/opt/buildhome/repo/src/pages/tools/index.vue?macro=true";
import { default as registerbbpLGTiIjGMeta } from "/opt/buildhome/repo/src/pages/tournaments/[id]/register.vue?macro=true";
import { default as createRGwwPJORP6Meta } from "/opt/buildhome/repo/src/pages/tournaments/create.vue?macro=true";
import { default as indexvDMCzA2DS2Meta } from "/opt/buildhome/repo/src/pages/tournaments/index.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    meta: aboutJrWDStVOuoMeta || {},
    component: () => import("/opt/buildhome/repo/src/pages/about.vue")
  },
  {
    name: "app-organisationId",
    path: "/app/:organisationId()",
    meta: indexHRck7nofVTMeta || {},
    component: () => import("/opt/buildhome/repo/src/pages/app/[organisationId]/index.vue")
  },
  {
    name: "app",
    path: "/app",
    meta: indexMjwB30oBzVMeta || {},
    component: () => import("/opt/buildhome/repo/src/pages/app/index.vue")
  },
  {
    name: onboardingcquesV5cqyMeta?.name,
    path: "/app/onboarding",
    meta: onboardingcquesV5cqyMeta || {},
    component: () => import("/opt/buildhome/repo/src/pages/app/onboarding.vue"),
    children: [
  {
    name: "app-onboarding",
    path: "",
    component: () => import("/opt/buildhome/repo/src/pages/app/onboarding/index.vue")
  }
]
  },
  {
    name: organisationsFxlbsxAryPMeta?.name,
    path: "/app/organisations",
    meta: organisationsFxlbsxAryPMeta || {},
    component: () => import("/opt/buildhome/repo/src/pages/app/organisations.vue"),
    children: [
  {
    name: "app-organisations",
    path: "",
    meta: indexhykZpJB4d7Meta || {},
    component: () => import("/opt/buildhome/repo/src/pages/app/organisations/index.vue")
  }
]
  },
  {
    name: "auth-forgot",
    path: "/auth/forgot",
    meta: indexuCbDhv9SpSMeta || {},
    component: () => import("/opt/buildhome/repo/src/pages/auth/forgot/index.vue")
  },
  {
    name: "auth-forgot-verify",
    path: "/auth/forgot/verify",
    meta: verifygN1RQfaAybMeta || {},
    component: () => import("/opt/buildhome/repo/src/pages/auth/forgot/verify.vue")
  },
  {
    name: "auth-reset",
    path: "/auth/reset",
    meta: resethLUtvJryhKMeta || {},
    component: () => import("/opt/buildhome/repo/src/pages/auth/reset.vue")
  },
  {
    name: "auth-sign-in",
    path: "/auth/sign-in",
    meta: sign_45inJp618j7yErMeta || {},
    component: () => import("/opt/buildhome/repo/src/pages/auth/sign-in.vue")
  },
  {
    name: "auth-sign-up",
    path: "/auth/sign-up",
    meta: sign_45upk5k2qGXM7TMeta || {},
    component: () => import("/opt/buildhome/repo/src/pages/auth/sign-up.vue")
  },
  {
    name: "auth-verify-email",
    path: "/auth/verify-email",
    component: () => import("/opt/buildhome/repo/src/pages/auth/verify-email.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug(.*)*",
    component: () => import("/opt/buildhome/repo/src/pages/blog/[...slug].vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/opt/buildhome/repo/src/pages/contact.vue")
  },
  {
    name: "downloads",
    path: "/downloads",
    component: () => import("/opt/buildhome/repo/src/pages/downloads.vue")
  },
  {
    name: "email-verified",
    path: "/email-verified",
    meta: email_45verifiedgMaPQSOqsSMeta || {},
    component: () => import("/opt/buildhome/repo/src/pages/email-verified.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexhGpynX1cdDMeta || {},
    component: () => import("/opt/buildhome/repo/src/pages/index.vue")
  },
  {
    name: "live",
    path: "/live",
    meta: liveFvquQFepQyMeta || {},
    component: () => import("/opt/buildhome/repo/src/pages/live.vue")
  },
  {
    name: "pricing",
    path: "/pricing",
    component: () => import("/opt/buildhome/repo/src/pages/pricing.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacywMgArANxwsMeta || {},
    component: () => import("/opt/buildhome/repo/src/pages/privacy.vue")
  },
  {
    name: "products-accessories",
    path: "/products/accessories",
    component: () => import("/opt/buildhome/repo/src/pages/products/accessories/index.vue")
  },
  {
    name: "products",
    path: "/products",
    component: () => import("/opt/buildhome/repo/src/pages/products/index.vue")
  },
  {
    name: "products-scoreboards",
    path: "/products/scoreboards",
    component: () => import("/opt/buildhome/repo/src/pages/products/scoreboards/index.vue")
  },
  {
    name: "products-scoreboards-max",
    path: "/products/scoreboards/max",
    component: () => import("/opt/buildhome/repo/src/pages/products/scoreboards/max.vue")
  },
  {
    name: "products-scoreboards-mini",
    path: "/products/scoreboards/mini",
    component: () => import("/opt/buildhome/repo/src/pages/products/scoreboards/mini.vue")
  },
  {
    name: "sandbox",
    path: "/sandbox",
    meta: sandboxbxDj9y3wdlMeta || {},
    component: () => import("/opt/buildhome/repo/src/pages/sandbox.vue")
  },
  {
    name: "scores",
    path: "/scores",
    component: () => import("/opt/buildhome/repo/src/pages/scores/index.vue")
  },
  {
    name: "sports-canoe-polo",
    path: "/sports/canoe-polo",
    component: () => import("/opt/buildhome/repo/src/pages/sports/canoe-polo.vue")
  },
  {
    name: "sports",
    path: "/sports",
    meta: indexF4AwVzPwOzMeta || {},
    component: () => import("/opt/buildhome/repo/src/pages/sports/index.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsbOpDTp1t6dMeta || {},
    component: () => import("/opt/buildhome/repo/src/pages/terms.vue")
  },
  {
    name: "tools",
    path: "/tools",
    component: () => import("/opt/buildhome/repo/src/pages/tools/index.vue")
  },
  {
    name: "tournaments-id-register",
    path: "/tournaments/:id()/register",
    meta: registerbbpLGTiIjGMeta || {},
    component: () => import("/opt/buildhome/repo/src/pages/tournaments/[id]/register.vue")
  },
  {
    name: "tournaments-create",
    path: "/tournaments/create",
    meta: createRGwwPJORP6Meta || {},
    component: () => import("/opt/buildhome/repo/src/pages/tournaments/create.vue")
  },
  {
    name: "tournaments",
    path: "/tournaments",
    component: () => import("/opt/buildhome/repo/src/pages/tournaments/index.vue")
  }
]