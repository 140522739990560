<template>
  <div>
    <NuxtLoadingIndicator color="yellow"/>

    <NuxtLayout>
      <NuxtPage/>
    </NuxtLayout>
    
    <UNotifications />
  </div>
</template>

<script setup lang="ts">
const nuxtApp = useNuxtApp()
const { t } = useI18n()

useSeoMeta({
  title: t('name') + ' - ' + t('moto'),
  ogTitle: t('name') + ' - ' + t('moto'),
  description: t('description'),
  ogDescription: t('description'),
  ogImage: 'https://arenachamp.app/ogImage.png',
  twitterCard: 'summary_large_image',
});

useHead({
  title: t('name') + ' - ' + t('moto'),
  meta: [
    {
      name: "robots",
      content: "noindex",
    }
  ]
});

nuxtApp.hook('nav:click', () => {
  nuxtApp.$scrollToTop()
})
</script>

<style></style>
