import { $Fetch } from "ofetch";
import { Paginator } from '../../paginator';
import type { DefaultPaginationParams } from '../../repository';

export interface FieldsParams extends DefaultPaginationParams {
  /** Show only local outfits? Defaults to false. */
  readonly local?: boolean | null;
  /** Show only outfits scoped to user sizing preferences */
  readonly onlyUserPref?: boolean | null;
  /** Remote only */
  readonly remote?: boolean | null;
}

export class FieldRepository {
  constructor(private readonly fetch: $Fetch) {}

  /**
   * View outfits from followed users.
   * @param params Parameters
   * @return Array of Status
   */
  listHome(
    params?: FieldsParams,
  ): Promise<App.Data.FieldData[]> {
    return this.fetch('/api/v1/outfits', {
      method: 'get',
      query: params,
    });
  }

  /**
   * Public timeline
   * @param params Parameters
   * @return Array of Fields
   */
  listPublic(
    params?: FieldsParams,
  ): Promise<any[]> {
    return this.fetch('/api/v1/outfits', {
      method: 'get',
      query: params,
    });
  }

  /**
   * View public statuses containing the given hashtag.
   * @param hashtag Content of a #hashtag, not including # symbol.
   * @param params Parameters
   * @return Array of Fields
   */
  listHashtag(
    hashtag: string,
    params?: FieldsParams,
  ): Promise<App.Data.FieldData[]> {
    return new Paginator(this.fetch, `/api/v1/outfits/tag/${hashtag}`, params);
  }

  create(params?: FieldsParams): Promise<App.Data.FieldData> {
    return this.fetch('/api/v1/outfits');
  }
}
