import { $Fetch } from "ofetch";

export class AuthRepository {
  constructor(private readonly fetch: $Fetch) {}
  /**
   * Login to a User account.
   * @param params Parameters
   * @return UserData
   */
  async login(params?: any): Promise<App.Data.UserData> {
    return await this.fetch("/auth/login", {
      method: "post",
      body: params,
    });
  }

  /**
   * Logout of a User account.
   * @returns 
   */
  async logout(): Promise<any> {
    return await this.fetch("/auth/logout", { method: "post" });
  }

  /**
   * Register a new User account.
   * @param name
   * @param email
   * @param password
   * @param password_confirmation
   * @returns
   */
  async register(
    params: object
  ): Promise<any> {
    return await this.fetch("/auth/register", {
      method: "post",
      body: { ...params },
    });
  }

    /**
   * Register a new User account.
   * @param email
   * @returns
   */
    async earlyAccess(
      params: object
    ): Promise<any> {
      return await this.fetch("/auth/early-access", {
        method: "post",
        body: { ...params },
      });
    }

  /**
   * Retrieve auth user.
   */    
  async user(): Promise<App.Data.UserData> {
    return await this.fetch("/api/v1/session");
  }

  /**
   * Trigger forgot password for a User account.
   * @param params Parameters
   * @return UserData
   */
  async forgotPassword(email: string): Promise<any> {
    return await this.fetch("/auth/forgot-password", {
      method: "post",
      body: {
        email,
      },
    });
  }

  /**
   * Trigger reset password for a User account.
   * @param params Parameters
   * @return UserData
   */
  async resetPassword(
      token: string,
      email: string,
      password: string,
      password_confirmation: string
    ): Promise<any> {
    return await this.fetch("/auth/reset-password", {
      method: "post",
      body: {
        email,
        token,
        password,
        password_confirmation,
      }
    });
  }

  /**
   * Trigger verify email for a User account.
   * @param params Parameters
   * @return UserData
   */
  async verifyEmail(
    id: string,
    hash: string
  ): Promise<any> {
    return await this.fetch(`/auth/verify-email/${id}/${hash}`, {
      method: "post",
    });
  }

  /**
   * Trigger resending of verify email for a User account.
   * @param params Parameters
   * @return UserData
   */
  async resendVerifyEmail(
    email: string 
  ): Promise<any> {
    return await this.fetch("/auth/resend-email", {
      method: "post",
      body: {
        email,
      },
    });
  }
}
