import revive_payload_client_w3KHzFc8zT from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.10.0_rollup@4.21.3_typescript@5.6.2_vite@5.4.5/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_dsWcw4J5pS from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.10.0_rollup@4.21.3_typescript@5.6.2_vite@5.4.5/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_q5zEmv8lXh from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.10.0_rollup@4.21.3_typescript@5.6.2_vite@5.4.5/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_nOEoskUBV9 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-site-config@2.2.18_rollup@4.21.3_vite@5.4.5_vue@3.5.5/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_giYrMW6eFj from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.10.0_rollup@4.21.3_typescript@5.6.2_vite@5.4.5/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_OTEDKmKn76 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.10.0_rollup@4.21.3_typescript@5.6.2_vite@5.4.5/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_E1o4CF4cLG from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.10.0_rollup@4.21.3_typescript@5.6.2_vite@5.4.5/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_jBXomsPiYI from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.10.0_rollup@4.21.3_typescript@5.6.2_vite@5.4.5/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_AlXoeLzknP from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.5.4_rollup@4.21.3_typescript@5.6.2_vue@3.5.5/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_xYpMM8LJDr from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.10.0_rollup@4.21.3_typescript@5.6.2_vite@5.4.5/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_zE8fwasUF8 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.2_rollup@4.21.3_vue@3.5.5/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_UB35idKllW from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.2_rollup@4.21.3_vue@3.5.5/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import slideovers_5pgOVUqOuU from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.4_focus-trap@7.6.0_qrcode@1.5.4_rollup@4.21.3_vite@5.4.5_vue@3.5.5/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_lme2SMlq9P from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.4_focus-trap@7.6.0_qrcode@1.5.4_rollup@4.21.3_vite@5.4.5_vue@3.5.5/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_a9rz1eZV8r from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui@2.18.4_focus-trap@7.6.0_qrcode@1.5.4_rollup@4.21.3_vite@5.4.5_vue@3.5.5/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_bYFa95F1nV from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_rollup@4.21.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_w5Uyh6Tc4h from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+icon@1.5.1_rollup@4.21.3_vite@5.4.5_vue@3.5.5/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_vYo8VTegSP from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.4.2_focus-trap@7.6.0_qrcode@1.5.4_rollup@4.21.3_vite@5.4.5_vue@3.5.5/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_RZN0Ct7nzX from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.4.2_focus-trap@7.6.0_qrcode@1.5.4_rollup@4.21.3_vite@5.4.5_vue@3.5.5/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_uAbZWzNjvQ from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.4.2_focus-trap@7.6.0_qrcode@1.5.4_rollup@4.21.3_vite@5.4.5_vue@3.5.5/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import client_8y0XQRGs9x from "/opt/buildhome/repo/src/api/plugins/client.ts";
import echo_HksZXjvotB from "/opt/buildhome/repo/src/api/plugins/echo.ts";
import _1_scroll_to_top_WX31OCL1jk from "/opt/buildhome/repo/src/plugins/1.scroll-to-top.ts";
import hydration_client_7uir9Lpz2B from "/opt/buildhome/repo/src/plugins/hydration.client.ts";
import sentry_client_config_cPKJD40CT4 from "/opt/buildhome/repo/src/plugins/sentry.client.config.ts";
import sonner_client_cxteQUnCKH from "/opt/buildhome/repo/src/plugins/sonner.client.ts";
import vue_query_JC9gnf7CA1 from "/opt/buildhome/repo/src/plugins/vue-query.ts";
export default [
  revive_payload_client_w3KHzFc8zT,
  unhead_dsWcw4J5pS,
  router_q5zEmv8lXh,
  _0_siteConfig_nOEoskUBV9,
  payload_client_giYrMW6eFj,
  navigation_repaint_client_OTEDKmKn76,
  check_outdated_build_client_E1o4CF4cLG,
  chunk_reload_client_jBXomsPiYI,
  plugin_vue3_AlXoeLzknP,
  components_plugin_KR1HBZs4kY,
  prefetch_client_xYpMM8LJDr,
  switch_locale_path_ssr_zE8fwasUF8,
  i18n_UB35idKllW,
  slideovers_5pgOVUqOuU,
  modals_lme2SMlq9P,
  colors_a9rz1eZV8r,
  plugin_client_bYFa95F1nV,
  plugin_w5Uyh6Tc4h,
  scrollbars_client_vYo8VTegSP,
  presets_RZN0Ct7nzX,
  variables_uAbZWzNjvQ,
  client_8y0XQRGs9x,
  echo_HksZXjvotB,
  _1_scroll_to_top_WX31OCL1jk,
  hydration_client_7uir9Lpz2B,
  sentry_client_config_cPKJD40CT4,
  sonner_client_cxteQUnCKH,
  vue_query_JC9gnf7CA1
]