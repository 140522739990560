<template>
    <div class="field-container">
      <svg v-for="(field, index) in fields" :key="index" :viewBox="field.viewBox" class="field-svg">
        <!-- Draw the outline of the field -->
        <path :d="field.d" class="field-outline"/>
        <!-- Draw the moving pulses along the outline -->
        <circle
          v-for="pulse in field.pulses"
          v-show="pulse.visible"
          :key="pulse.id"
          :r="pulse.radius"
          :fill="pulse.color"
          :style="{ animationDuration: field.pulseDuration + 's' }"
        >
         

          <animateMotion
            :dur="field.pulseDuration + 's'"
            :begin="pulse.begin + 's'"
            repeatCount="indefinite"
            :path="field.d"
          />
        </circle>
      </svg>
    </div>
  </template>
  
  <script setup>
  import { ref } from 'vue';
  
  const colors = [
  "#FFC452", // brand
  "#F3674A", // brand
  "#4A8EF3", // brand-third
  "#61C371", // brand-fourth
];

const getRandomColor = () => {
  return colors[Math.floor(Math.random() * colors.length)];
};

  const fields = ref([
    {
      viewBox: '-1 -1 87 60',
      d: 'M42.5 57.5L0.999998 57.5L0.999998 49M42.5 57.5L42.5 38.5M42.5 57.5L84 57.5L84 50M42.5 1L1 1L1 8.5M42.5 1L42.5 20M42.5 1L84 1L84 9.5M42.5 20C42.5 20 33 20 33 29.25C33 38.5 42.5 38.5 42.5 38.5M42.5 20C42.5 20 52.5 20 52.5 29.25C52.5 38.5 42.5 38.5 42.5 38.5M42.5 20L42.5 38.5M1 8.5L17.5 8.5L17.5 20M1 8.5L0.999999 19.25M0.999998 49L17.5 49L17.5 38.5M0.999998 49L0.999998 37.5M0.999999 19.25L8 19.25L8 37.5L0.999998 37.5M0.999999 19.25L0.999998 37.5M17.5 20C17.5 20 22.5 22.5 22.5 29.25C22.5 35.5 17.5 38.5 17.5 38.5M17.5 20L17.5 38.5M84 50L68 50L68 38.5M84 50L84 39.25M84 9.5L68 9.5L68 20M84 9.5L84 21M84 39.25L77.5 39.25L77.5 21L84 21M84 39.25L84 21M68 38.5C68 38.5 63 35.5 63 29.25C63 23 68 20 68 20M68 38.5L68 20',
      pulses: [
        { id: 1, radius: 2, begin: 0, color: getRandomColor() },   
        { id: 2, radius: 2, begin: 1, color: getRandomColor() },  
        { id: 3, radius: 2, begin: 2, color: getRandomColor() },  
        { id: 4, radius: 2, begin: 3, color: getRandomColor() },  
        { id: 1, radius: 2, begin: 4, color: getRandomColor() },  
        { id: 2, radius: 2, begin: 5, color: getRandomColor() }, 
        { id: 3, radius: 2, begin: 6, color: getRandomColor() }, 
        { id: 4, radius: 2, begin: 7, color: getRandomColor() },
      ],
      pulseDuration: 20
    },
  ]);


  fields.value.forEach(field => {
  field.pulses.forEach(pulse => {
    pulse.visible = false; // Start with the pulse invisible
    setTimeout(() => {
      pulse.visible = true; // Make the pulse visible after the delay
    }, pulse.begin * 1000); // Convert seconds to milliseconds
  });
});

  </script>
  
  <style scoped>
  .field-container {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto;
  }
  
  .field-svg {
    width: 400px; /* Adjust as needed */
    height: 200px; /* Adjust as needed */
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
    overflow: visible; /* Add this line */
  }
  
  .field-outline {
    fill: none;
    stroke: #fff;
    stroke-width: 1.5;
  }
  </style>
  