import { $Fetch } from "ofetch";

export class OrganisationMemberRepository {
  constructor(private readonly fetch: $Fetch) {}

    create(organisationId: string, userId: string): Promise<App.Data.OrganisationMemberData> {
      return this.fetch(`/api/v1/organisations/${organisationId}/member`, {
        method: 'post',
        body: { user: { id: userId } }
      });
    }
}
