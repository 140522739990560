<template>
     <div class="relative items-center bg-stone-900 hidden w-0 flex-1 @lg:flex flex-col">
        <div class="m-auto">
            <ClientOnly>
                <FieldAnimation />
            </ClientOnly>
            <div class="w-full items-center m-auto font-bold text-center flex text-white ">
                <span class="m-auto">Make anywhere an arena</span>
            </div>
        </div>
    </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>

</style>