import { Paginator } from '../../paginator';
import { $Fetch } from "ofetch";
import type { DefaultPaginationParams } from '../../repository';

export interface NotificationsParams extends DefaultPaginationParams {
  /** Show only specific types of notifications. */
  readonly type?: App.Enums.NotificationType | null;
}

export class NotificationRepository {
  constructor(private readonly fetch: $Fetch) {}
  /**
   * Get notifications for the current user.
   * @param params Parameters
   * @return NotificationData
   */
  list(
    params?: NotificationsParams,
  ): Paginator<App.Data.NotificationData[], NotificationsParams> {
    return new Paginator(this.fetch, '/api/v1/notifications', params);
  }


}
