import { $Fetch } from "ofetch";

export class ConfigRepository {
  constructor(private readonly fetch: $Fetch) {}

  defaults(): Promise<App.Data.AppDefaultConfigData> {
    return this.fetch('/api/v1/config/defaults', {
      method: 'get',
    });
  }
}
