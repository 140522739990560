/* eslint-disable unicorn/no-thenable */
import { $Fetch } from "ofetch";

/**
 * A common paginator for API responses.
 * Optional handling of virtualization of the API response
 * @param fetch
 * @param params
 * @param cursor
 * @param virtualize
 */
export class Paginator<Entity, Params = never>
  implements AsyncIterableIterator<Entity>, PromiseLike<Entity>
{
  constructor(
    private readonly fetch: $Fetch,
    private params?: Params,
    private cursor?: string,
    private readonly virtualize = false,
    private readonly virtualizeOptions?: {
      readonly pageSize?: number;
      readonly initialSize?: number;
      readonly maxSize?: number;
    },
  ) {}

  private nextPath?: string;
  private nextParams?: Params;

  async next(): Promise<IteratorResult<Entity, undefined>> {
    if (this.nextPath == undefined) {
      return { done: true, value: undefined };
    }

    const response = await this.fetch.raw(this.nextPath, {
        method: "get",
        query:  this.nextParams as Record<string, unknown>,
    });

    const nextCursor = response._data?.meta?.next_page_url as string | undefined;
    this.nextPath = nextCursor
    this.nextParams = "" as Params; // todo add params once we have them

    return {
      done: false,
      value: response._data.data as Entity,
    };
  }

  async return(
    value?: undefined | Promise<undefined>,
  ): Promise<IteratorResult<Entity, undefined>> {
    this.clear();
    return {
      done: true,
      value: await value,
    };
  }

  async throw(e: unknown): Promise<IteratorResult<Entity, undefined>> {
    this.clear();
    throw e;
  }

  then<TResult1 = Entity, TResult2 = never>(
    onfulfilled: (
      value: Entity,
    ) => TResult1 | PromiseLike<TResult1> = Promise.resolve,
    onrejected: (
      reason: unknown,
    ) => TResult2 | PromiseLike<TResult2> = Promise.reject,
  ): Promise<TResult1 | TResult2> {
    // we assume the first item won't be undefined
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.next().then((value) => onfulfilled(value.value!), onrejected);
  }

  [Symbol.asyncIterator](): AsyncGenerator<
    Entity,
    undefined,
    Params | undefined
  > {
    return this;
  }

  private clear() {
    this.nextPath = undefined;
    this.nextParams = undefined;
  }

  clone(): Paginator<Entity, Params> {
    return new Paginator(this.fetch, this.nextPath, this.nextParams);
  }
}
