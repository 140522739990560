import { $Fetch } from "ofetch";
import type { DefaultPaginationParams } from '../../repository';

export interface TournamentsParams extends DefaultPaginationParams {
  /** Show only tournaments for specific discipline */
  readonly discipline?: string;
}

export class TournamentRepository {
  constructor(private readonly fetch: $Fetch) {}

   /**
   * Public tournaments
   * @param params Parameters
   * @return Array of Fields
   */
   listPublic(
    params?: TournamentsParams,
  ): Promise<any[]> {
    return this.fetch('/api/v1/public/tournaments', {
      method: 'get',
      query: params,
    });
  }

  /**
   * View tournaments scoped to user.
   * @param params Parameters
   * @return Array of Status
   */
  listHome(
    params?: TournamentsParams,
  ): Promise<App.Data.TournamentData[]> {
    return this.fetch('/api/v1/tournaments', {
      method: 'get',
      query: params,
    });
  } 

  create(params: any): Promise<App.Data.TournamentData> {
    return this.fetch('/api/v1/tournaments', {
      method: 'post',
      body: params,
    });
  }

  retrieve(id: string): Promise<App.Data.TournamentData> {
    return this.fetch(`/api/v1/tournaments/${id}`);
  }

  update(id: string, params?: TournamentsParams): Promise<App.Data.TournamentData> {
    return this.fetch(`/api/v1/tournaments/${id}`, {
      method: 'put',
      body: params,
    });
  }
}
