import { $Fetch } from "ofetch";

interface ListResponse {
  readonly data: App.Data.OrganisationData[];
  readonly meta: {
    readonly current_page: number;
    readonly from: number;
    readonly last_page: number;
    readonly per_page: number;
    readonly total: number;
  };
}

interface ListUserOrganisationOptions {
  readonly userId: string;
}

interface ListAllOrganisationOptions {
  readonly search: string;
}

export class OrganisationRepository {
  constructor(private readonly fetch: $Fetch) {}


    /**
   * Retrieves a list of all organisations.
   * @returns {Promise<Organisation[]>} A promise that resolves to an array of Organisation objects.
   */
    listAll(params: ListAllOrganisationOptions): Promise<ListResponse> {
      return this.fetch('/api/v1/organisations', {
        method: 'get',
        query: params
      });
    }
  
    /**
     * Retrieves a list of organisations that the currently logged-in user is associated with.
     * @returns {Promise<Organisation[]>} A promise that resolves to an array of Organisation objects associated with the user.
     */
    listUser(params: ListUserOrganisationOptions): Promise<App.Data.OrganisationData[]> {
      return this.fetch(`/api/v1/users/${params.userId}/organisations`, {
        method: 'get',
      });
    }

    create(organisation: App.Data.OrganisationData): Promise<App.Data.OrganisationData> {
      return this.fetch('/api/v1/organisations', {
        method: 'post',
        body: organisation
      });
    }
}
