import routerOptions0 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.13.2_eslint@9.10.0_rollup@4.21.3_typescript@5.6.2_vite@5.4.5/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxt+ui-pro@1.4.2_focus-trap@7.6.0_qrcode@1.5.4_rollup@4.21.3_vite@5.4.5_vue@3.5.5/node_modules/@nuxt/ui-pro/modules/pro/runtime/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}