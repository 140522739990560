import { $Fetch } from "ofetch";

export class UserRepository {
  constructor(private readonly fetch: $Fetch) {}
  /**
   * Update a User.
   * @param params Parameters
   * @return UserData
   */
  async update(userId: string, params?: App.Data.UserData): Promise<App.Data.UserData> {
    return await this.fetch(`/api/v1/users/${userId}`, {
      method: "patch",
      body: params,
    });
  }
}
